<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-12-21 12:14:18
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-12-10 21:10:24
-->
<template>
  <el-select
    v-model="value"
    placeholder="请输入楼盘名称搜索"
    clearable
    filterable
    remote-show-suffix
    remote
    :remote-method="onRemoteMethod"
    :loading="loading"
    @change="onChange"
    :multiple="multiple"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
      <span>{{ item.label }}</span>
      <span
        :style="`color:var(${
          item.stopStatus == 0 ? '--el-color-success' : '--el-color-info'
        })`"
        >({{ item.stopStatusText }})</span
      >
    </el-option>
  </el-select>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      loading: false,
      options: [],
      query: {
        q: "",
      },
    };
  },
  emits: ["change"],
  props: {
    item: {
      type: Object,
      default: null,
    },
    /**
     * 是否多选
     */
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          if (!this.options.find((o) => o.value == this.item.id)) {
            this.value = this.item.id;
            var model = {
              value: this.item.id,
              label: this.item.name,
              stopStatus: this.item.stopStatus,
              stopStatusText: this.item.stopStatusText,
              tuijianDesc: this.item.tuijianDesc,
            };
            this.options.push(model);
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.onSearch();
  },
  methods: {
    /**
     * 选择更改
     */
    onChange(e) {
      var item = this.options.find((o) => o.value == e);
      this.$emit("change", e, { ...item, name: item.label, id: item.value });
    },

    /**
     * 远程检索
     */
    onRemoteMethod(q) {
      this.query.q = "";
      if (q) {
        this.query.q = q;
        this.onSearch();
      }
    },

    onSearch() {
      this.loading = true;
      this.$http
        .post(`admin/v1/estate/base`, this.query)
        .then((res) => {
          if (res.code == 0) {
            if (res.data) {
              this.options = res.data.data.map((x) => {
                return {
                  label: x.name + `(${x.stopStatusText})`,
                  value: x.id,
                  stopStatus: x.stopStatus,
                  stopStatusText: x.stopStatusText,
                  tuijianDesc: x.tuijianDesc,
                };
              });
            }
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
